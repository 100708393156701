import moment from "moment"


let apiBase = 'https://diary.gisborneherald.co.nz/api/v1'

if (process.env.NODE_ENV !== "production") {
    apiBase = 'http://localhost:3000/v1'
    console.log('production')
}

export const getEntriesByDay = async (date, accessToken) => {

    const resp = await fetch(
        apiBase + "/entries?date=" + moment(date).format('YYYY-MM-DD') + "&limit=1000",
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })

    const json = await resp.json()

    if (json.code) {
        throw json
    }

    return json.results

}