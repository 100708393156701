
import { useState } from 'react'
import moment from 'moment'
import './EntryListItem.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

export default function EntryListItem({ value, onClick }) {

    console.log(value)

    const formatValue = (value) => {
        if (value) {
            return value
        } else {
            return '-'
        }
    }

    const formatDate = (date) => {

        const formated = moment(value.date).format('HH:mm A')
        //const timeParts = date.split('T')[1].split('.')[0].split(':')

        //const hours = parseInt(timeParts[0])
        //const mins = parseInt(timeParts[1])


        //let flipped = moment(date).format('hh:mm A');
        
        //if(flipped.indexOf('AM')){
            //flipped = flipped.replace('AM','PM') 
        //}else{
            //flipped = flipped.replace('PM','AM') 
        //}

        //return hours + ':' + mins;

        return formated;
    }

    return (
        <div className="entry" onClick={onClick}>
            <div className="entry-col1">
            <div className="entry-time">{moment(value.date).format('hh:mm A')}</div>
            </div>
            <div className="entry-col2">
                <div className="entry-title">{value.title ? value.title : 'Untitled'}</div>
            </div>
            <div className="entry-col3">
                <span className="entry-arrow">
                    <FontAwesomeIcon icon={faAngleRight} />
                </span>
            </div>
        </div>
    )
}


/*<div className="entry-time">{moment(value.date).format('HH:mm A')}</div>*/