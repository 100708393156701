
import './SplashScreen.scss'
import Logo from '../images/Logo.svg'

export default function SplashScreen () {
    return (
        <div className="splash-screen">
            <img className="splash-screen-img" src={Logo} />
        </div>
    )
}