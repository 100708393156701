import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';

let clientId = 'liAueLwW6PnXB2tXuwBgxuy8DliTo0B9'

if (process.env.NODE_ENV !== "production") {
  clientId = '0wOSCxjzrGVnSGPO5CglsXZTB3d0qFiU'
}

ReactDOM.render(  
  <React.Fragment>
    <Auth0Provider
       domain="dev-t-kzo4qa.us.auth0.com"
       clientId={clientId}
       redirectUri={window.location.origin}
       audience="https://photodiary.gisborneherald.co.nz/api"
       scope="read:photographers read:entries">
        <App />     
    </Auth0Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
