import { useState, useRef, useEffect } from 'react'
import './App.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Calendar from './components/Calendar'
import AddNewEntryButton from './components/AddNewEntryButton'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import Header from './components/Header'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EntryList from './components/EntryList';
import { getEntriesByDay } from './photo-diary.api'
import { useAuth0 } from '@auth0/auth0-react';
import useEntries from './hooks/useEntries'
import EntryView from './components/EntryView'
import SplashScreen from './components/SplashScreen'

const localizer = momentLocalizer(moment)

const formats = {
  agendaHeaderFormat: ({ start, end }) => (
    moment(start).format('DD/MM/yyyy') + '-' + moment(end).format('DD/MM/yyyy')
  )
}

function App() {

  const [selectedDate, setSelectedDate] = useState(new Date())
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const entries = useEntries(isAuthenticated, selectedDate)
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [showEntry, setShowEntry] = useState(false)


  useEffect(() => {

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect()
    }
    

  }, [isLoading, isAuthenticated])

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleNavigate = (date) => {
    setSelectedDate(date)
  }

  const handleItemClick = entry => {
    setSelectedEntry(entry)
    setShowEntry(true)
  }

  return (
    <>
      {!isAuthenticated && <SplashScreen />}
      {isAuthenticated &&
        <div>
          <Calendar value={selectedDate} onChange={handleDateChange} />
          <EntryList date={selectedDate} entries={entries.entries} isLoading={entries.isLoading} onItemClick={handleItemClick} />
          <EntryView value={selectedEntry} show={showEntry} onHide={() => setShowEntry(false)} />
        </div>}
    </>
  )
}

export default App;

/*

*/
/*
<AddNewEntryButton />
<BigCalendar
                date={selectedDate}
                localizer={localizer}
                events={[]}
                defaultView="day"
                formats={formats}
                culture="en-GB"
                onNavigate={handleNavigate}
              />

*/