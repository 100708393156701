

import { useState, useEffect } from 'react'
import EntryListItem from './EntryListItem'
import moment from 'moment'
import './EntryList.scss'
import { ListGroup } from 'react-bootstrap'

const EntryCount = ({count}) => {
  return count!==0 && <div className="entry-list-count">{count} total</div>
}

const NoEntries = ({count}) => {
  return count===0 && <div className="entry-list-no-results">No entries</div>
}

export default function EntryList({ date, entries, isLoading, onItemClick }) {

  return (
    <div className="entry-list">
      <div className="entry-header">
          <div className="entry-list-title">
            {moment(date).format('MMMM D, YYYY')}
          </div>
          <EntryCount count={entries.length} />
      </div>
      <div className="entry-list-content">
        {!isLoading && <ListGroup>
          {entries.map((entry,idx) =>
            <ListGroup.Item  key={entry.id}>
              <EntryListItem value={entry} onClick={()=>onItemClick(entry)} />
            </ListGroup.Item> )}
        </ListGroup>}
        {isLoading && <div className="entry-list-loading">Loading....</div>}
        {!isLoading && <NoEntries count={entries.length} />}
      </div>
    </div>
  )
}