
import React, { useEffect, useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { getEntriesByDay } from '../photo-diary.api'

export default function useEntries(isAuthenticated, date) {

    const [value, setValue] = useState({ isLoading: true, entries: [] })
    const { getAccessTokenSilently } = useAuth0();

    useEffect(async () => {

        if(isAuthenticated) {
            setValue({...value,isLoading:true})

            const accessToken = await getAccessTokenSilently({
                audience: `https://photodiary.gisborneherald.co.nz/api`,
                scope: 'read:entries'
            });
    
            getEntriesByDay(date, accessToken)
                .then(entries => {
                    setValue({ isLoading: false, entries: entries })
                })
                .catch(error => {
                    setValue({ isLoading: false, entries: [] })
                })
        }
       

    }, [date, isAuthenticated])

    return value

}

