import { useState, useEffect } from 'react'
import './EntryView.scss'
import Offcanvas from 'react-bootstrap/Offcanvas'
import moment from 'moment'

export default function EntryView({ value, show, onHide }) {

    const formatValue = value => {
        return value ? value : '-'
    }

    return (
        <>
            {value &&
                <Offcanvas className="entry-view" placement="bottom" show={show} onHide={onHide}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{value.title}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="entry-view-date">{moment(value.date).format("dddd, MMMM D, YYYY")}</div>
                        <div className="entry-view-content">{formatValue(value.details)}</div>
                        <div className="entry-view-details">
                            Contact: <span className="entry-view-details-value">{formatValue(value.contact)}</span><br />
                            Location: <span className="entry-view-details-value">{formatValue(value.location)}</span><br />
                            Requested by: <span className="entry-view-details-value">{formatValue(value.requester)}</span><br />
                            Photographer: <span className="entry-view-details-value">{formatValue(value.photographer)}</span><br />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            }
        </>
    )
}
