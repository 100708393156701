import { useState, useRef, useEffect } from 'react'
import { Calendar as Cal } from 'react-calendar';
import { UniversalStyle as Style } from 'react-css-component'
import { useWindowWidth } from '@react-hook/window-size'
import './Calendar.scss'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight,faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export default function Calendar({ value, onChange }) {

  const [css, setCss] = useState('')
  const [view, setView] = useState('month')
  const calenderRef = useRef()
  const onlyWidth = useWindowWidth()
  const [activeDate,setActiveDate] = useState(new Date())

  useEffect(() => {

    const tiles = document.getElementsByClassName("react-calendar__tile");
    const height = tiles[0].offsetWidth + 'px';

    setCss(`
          .react-calendar__tile {
            height: ${height}
          }

          .react-calendar__month-view__weekdays__weekday {
            height: ${height}
          }
        `);

  }, [onlyWidth])

  const handleNext = () => {
    setActiveDate(
        moment(activeDate)
          .startOf('month')
          .add(1,'month')
          .toDate()
    )
  }

  const handlePrev = () => {
    setActiveDate(
      moment(activeDate)
        .startOf('month')
        .add(-1,'month')
        .toDate()
  )
  }

  return (
    <>
      <Style css={css} />
      <div className="calendar-navigation">
        <button className="calendar-navigation-prev" onClick={handlePrev}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <div className="calendar-navigation-label">{moment(activeDate).format('MMMM YYYY')}</div>
        <button className="calendar-navigation-next" onClick={handleNext}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
      
      <Cal
        activeStartDate={activeDate}
        showNavigation={false}
        locale="en-NZ"
        ref={calenderRef}
        value={value}
        onClickDay={onChange}
        formatShortWeekday={(locale, date) => moment(date).format('dd').substr(0, 1)} 
        />
    </>

  )
}